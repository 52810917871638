import React from 'react';

class HubspotForm extends React.Component {
    componentDidMount() {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        
        script.addEventListener('load', () => {
            if(window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "20046498",
                    formId: "b51b0a14-aa13-4bbe-a2ae-3552c2f5ff5b",
                    target: '#hubspotForm'
            })
            }
        });
    }
    
      render() {
        return (
            <div>
              <div id="hubspotForm"></div>
            </div>
      );
    }
  }

  export default HubspotForm;
  