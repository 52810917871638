import * as React from 'react'
import Layout from '../components/layout'
import ContactForm from '../components/ContactForm/contact-form'

const ContactPage = () => {
  return (
    <Layout pageTitle="Helygan Digital - Contact" wrapperClass="flex flex-col h-screen" headerDisplayBackButton={true}>
      <ContactForm />
    </Layout>
  )
}

export default ContactPage