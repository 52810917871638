import * as React from 'react'
import HubSpotForm from './hubspot-form';
import { MailIcon } from '@heroicons/react/outline'

export default function ContactForm() {
  return (
    <div className="relative bg-white flex-1">

      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5 h-full">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Get in touch</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
             Do you have a project you would like to talk to us about?
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
             Contact us today via using the form on this page, or the email below.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <a className="ml-3 text-hely-orange" href="mailto:hello@helygan.digital">hello@helygan.digital</a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <HubSpotForm />
          </div>
        </div>
      </div>
    </div>
  )
}
